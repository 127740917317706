import React from "react"
import { Flex, Title, Text } from "../../components/system"
import theme from "../../components/theme"

const Plan = ({ title, description, price, features, onClick }) => {
  return (
    <Flex
      flexDirection="column"
      border="1px solid black"
      borderRadius={6}
      p={4}
      maxWidth={600}
    >
      <Title fontSize={[5, 6]} color={theme.colors.secondary} mb={2}>
        {title}
      </Title>
      {/* <Text>{description}</Text> */}
      <Title mt={3} mb={0}>
        {price}
      </Title>
      <Text>per Month</Text>
      <Flex flexDirection="column" alignItems="flex-start" p={[2, 4]} mb="auto">
        {features.map((feature, i) => (
          <li key={i} style={{ fontFamily: "sans-serif" }}>
            {feature}
          </li>
        ))}
      </Flex>
      <Flex
        borderRadius={4}
        bg={theme.colors.primary}
        color="white"
        onClick={onClick}
        className="hover"
      >
        {/* <Text m={0} p={3}>
          Subscribe to {title}
        </Text> */}
        <Text m={0} p={3}>
          Subscribe
        </Text>
      </Flex>
    </Flex>
  )
}

export default Plan
