import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { loadStripe } from "@stripe/stripe-js"
import { Flex, Box, Grid } from "../../components/system"
import { useAuth0 } from "@auth0/auth0-react"
import { client } from "../../sanity"
import { navigate } from "@reach/router"

import Plan from "../../components/Community/Plan"
import Loading from "../../components/Loading"

const Subscribe = () => {
  const data = useStaticQuery(graphql`
    query plans {
      allSanityPlan(sort: { order: ASC, fields: _createdAt }) {
        nodes {
          title
          description
          price
          stripeID
          points
        }
      }
    }
  `)

  const { user } = useAuth0()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchUser()
  }, [])

  const fetchUser = async () => {
    const role = user["https://ilana.uk/roles"][0]
    const query = `*[_type == ${
      role === "Expert" ? '"expert"' : '"user"'
    } && uuid == $user]`

    const params = { user: user.sub }
    client
      .fetch(query, params)
      .then(user => {
        if (user[0].plan === undefined) {
          setLoading(false)
        } else {
          navigate("/community/membership")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const redirectToCheckout = async (e, price, plan) => {
    e.preventDefault()
    setLoading(true)
    const stripe = await loadStripe(process.env.GATSBY_STRIPE_PUBLIC)

    await fetch("/.netlify/functions/checkout", {
      method: "post",
      body: JSON.stringify({
        mode: "subscription",
        lineItems: [{ price: price, quantity: 1 }],
        successUrl: process.env.GATSBY_MEMBERSHIP_SUCCESS_URL,
        cancelUrl: process.env.GATSBY_MEMBERSHIP_CANCEL_URL,
        metadata: {
          user_id: user.sub,
          user_role: user["https://ilana.uk/roles"][0],
          plan: plan,
        },
      }),
    })
      .then(response => response.json())
      .then(data => {
        stripe
          .redirectToCheckout({
            sessionId: data,
          })
          .then(() => setLoading(false))
      })
      .catch(error => {
        console.log(error)
      })
  }

  if (loading) {
    return <Loading size={10} />
  } else {
    return (
      <Box>
        <Flex my={5}>
          <Grid
            gridTemplateColumns={["1fr", "1fr 1fr"]}
            gridGap={5}
            mb={7}
            width={["90%", "70%"]}
            maxWidth={1000}
          >
            {data &&
              data.allSanityPlan.nodes.map((plan, key) => (
                <Plan
                  key={key}
                  title={plan.title}
                  description={plan.description}
                  price={plan.price}
                  features={plan.points}
                  onClick={e =>
                    redirectToCheckout(
                      e,
                      plan.stripeID,
                      plan.title.toLowerCase()
                    )
                  }
                />
              ))}
          </Grid>
        </Flex>
      </Box>
    )
  }
}

export default Subscribe
